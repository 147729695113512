// @ts-check
import { initSchema } from "@aws-amplify/datastore";
import { schema } from "./schema";

const NotificationVisibility = {
  PUBLIC: "PUBLIC",
  PRIVATE: "PRIVATE",
};

const TypeTutorial = {
  VIDEO: "VIDEO",
  TEXT: "TEXT",
};

const SignalOperation = {
  OPEN: "OPEN",
  CLOSE: "CLOSE",
};

const SignalPosition = {
  BUY: "BUY",
  SELL: "SELL",
};

const SignalMode = {
  MANUAL: "MANUAL",
  AUTOMATIC: "AUTOMATIC",
};

const SubscriptionPlanType = {
  PROFESSIONAL: "PROFESSIONAL",
  PLATINUM: "PLATINUM",
  PREMIUM: "PREMIUM",
};

const {
  Noticia,
  Tutoriales,
  AuditedAccounts,
  Strategy,
  Envivos,
  Mentalidad,
  Signal,
  Modo,
  Certificate,
  User,
  SubscriptionPlan,
  KYC,
  Session,
} = initSchema(schema);

export {
  Noticia,
  Tutoriales,
  AuditedAccounts,
  Strategy,
  Envivos,
  Mentalidad,
  Signal,
  Modo,
  Certificate,
  User,
  NotificationVisibility,
  TypeTutorial,
  SignalOperation,
  SignalPosition,
  SignalMode,
  SubscriptionPlanType,
  SubscriptionPlan,
  KYC,
  Session,
};
