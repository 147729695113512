// import
import React from "react";
import Dashboard from "views/Dashboard/Dashboard.js";
import Tables from "views/Dashboard/Tables.js";

import "antd/dist/antd.css";
import "./style.css";

import { HomeIcon, PersonIcon } from "components/Icons/Icons";

import TablesSignals from "views/Dashboard/TablesSignals";
import Manual from "views/Dashboard/Manual";

import { HiBookOpen, HiCalendar, HiUsers } from "react-icons/hi";
import { FiUserX } from "react-icons/fi";
import { AiTwotoneNotification } from "react-icons/ai";
import {
  FaPlane,
  FaPlaneArrival,
  FaPlaneDeparture,
  FaTools,
  FaUser,
} from "react-icons/fa";
import {
  BiBarChartAlt,
  BiBarChartSquare,
  BiChart,
  BiMessageSquare,
  BiPlayCircle,
  BiSolidVideoRecording,
  BiWorld,
} from "react-icons/bi";
import { BiNews } from "react-icons/bi";
import TablesNews from "views/Dashboard/TablesNews";
import TablesNewsPrincipales from "views/Dashboard/TablesNewsPrincipales";
import TablesUserDeleted from "views/Dashboard/TablesUserDeleted";
import TablesEnvivos from "views/Dashboard/TablesEnvivos";
import TablesTravelUsers from "views/Dashboard/TablesTravelUsers";
import TablesTravelActivations from "views/Dashboard/TablesTravelActivations";
import TablesTravelSearchActivations from "views/Dashboard/TablesTravelSearchActivations";
import TablesTravelSearchTravels from "views/Dashboard/TablesTravelSearchTravels";
import { Card } from "@chakra-ui/react";
import TablesMentalidad from "views/Dashboard/TablesMentalidad";
import TablesTableRowAuditedAccounts from "components/Tables/TablesTableRowAuditedAccounts";
import TablesAuditedAccounts from "views/Dashboard/TablesAuditedAccounts";
import TablesStrategy from "views/Dashboard/TablesStrategy";
import Profile from "views/Dashboard/Profile";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Home",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    name: "CUENTA",
    category: "account",
    state: "pageCollapse",
    views: [
      {
        path: "/profile",
        name: "Perfil",
        icon: <PersonIcon color="inherit" size={22} />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/admin",
      },
    ],
  },

  /* {
    name: "TUTORIALES",
    category: "robots",
    state: "pageCollapse",
    views: [
      {
        path: "/all-tutorials",
        name: "Todos",
        icon: <BiPlayCircle color="inherit" />,
        component: Card,
        layout: "/admin",
      },
    ],
  }, */
];
export default dashRoutes;
