import React, { useState, useEffect } from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
// Chakra imports
import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Switch,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import avatar5 from "assets/img/avatars/user.png";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { FaFileAlt } from "react-icons/fa";
import { HiBellAlert } from "react-icons/hi2";
import { Tooltip } from "antd";
import { Loader } from "@aws-amplify/ui-react";
import { BsFillShieldLockFill } from "react-icons/bs";
import Swal from "sweetalert2";
import TablesDevice from "./TablesDevice.js";
import * as queries from "../../graphql/queries.js";
import * as mutations from "../../graphql/mutations";

function Profile() {
  const { colorMode } = useColorMode();

  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const iconColor = useColorModeValue("navy.500", "white");
  const bgProfile = useColorModeValue("hsla(0,0%,100%,.8)", "navy.800");
  const borderProfileColor = useColorModeValue("white", "transparent");
  const emailColor = useColorModeValue("gray.400", "gray.300");

  const [user, setUser] = useState();
  const [profile, setProfile] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [hasiOSSession, setHasiOSSession] = useState(false);
  const [hasAndroidSession, setHasAndroidSession] = useState(false);
  const [userID, setUserID] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [currentUserName, setCurrentUserName] = useState("");

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      setUser(user);
    });
  }, []);

  useEffect(() => {
    if (profile) {
      setHasiOSSession(profile.hasiOSSession || false);
      setHasAndroidSession(profile.hasAndroidSession || false);
    }
  }, [profile]);

  const deleteDevice = async () => {
    if (profile) {
      const userDetailstoUpdate = {
        id: profile.id,
        _version: profile._version,
        hasiOSSession: false,
        hasAndroidSession: false,
        deviceOSName: "",
        deviceModelName: "",
        deviceName: "",
        deviceBrand: "",
        activeDate: "",
      };

      Swal.fire({
        title: "¿Estás seguro?",
        text: "Cerrarás las sesiones actuales",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          try {
            await API.graphql(
              graphqlOperation(mutations.updateUser, {
                input: userDetailstoUpdate,
              }),
            );
            setIsLoading(false);
            Swal.fire("El dispositivo fue eliminado", "", "success");
            componenteMontado();
          } catch (error) {
            setIsLoading(false);
            Swal.fire(
              "Algo salió mal, por favor intenta de nuevo",
              "",
              "error",
            );
          }
        }
      });
    }
  };

  async function getUserProfile(sub) {
    setIsLoading(true);
    try {
      const result = await API.graphql(
        graphqlOperation(queries.getUser, { id: sub }),
      );
      setProfile(result.data.getUser);
      setIsLoading(false);
      return result.data.getUser;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  }

  async function componenteMontado() {
    const userID = await Auth.currentSession().then((data) => {
      setUserID(data.idToken.payload.sub);
      setCurrentUser(data.idToken.payload);
      return data.idToken.payload.sub;
    });

    const userName = await Auth.currentAuthenticatedUser().then((data) => {
      setCurrentUserName(data.username);
      return data.username;
    });

    const profile = await getUserProfile(userID);

    if (!profile) {
      console.log("Usuario no creado en la BD, creando...");
    } else {
      console.log("El usuario en BD es =>", profile);
    }
  }

  useEffect(() => {
    componenteMontado();
  }, []);

  if (isLoading) {
    return (
      <Flex
        direction="column"
        pt={{ base: "120px", md: "75px" }}
        alignContent="center"
        alignItems="center"
      >
        <Card
          style={{
            boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
            border: "1px solid #e7eaf3",
            borderRadius: "1rem",
            width: "450px",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          <div
            style={{
              width: "auto",
              height: "300px",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Loader variation="linear" filledColor={"#f9a640"} />
          </div>
        </Card>
      </Flex>
    );
  }

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px", lg: "100px" }}>
      <Flex
        direction={{ sm: "column", md: "row" }}
        mb="24px"
        maxH="330px"
        justifyContent={{ sm: "center", md: "space-between" }}
        align="center"
        backdropFilter={{ md: "blur(1px)", lg: "blur(1px)", sm: "none" }}
        boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
        border="1.5px solid"
        borderColor={borderProfileColor}
        bg={bgProfile}
        p="24px"
        borderRadius="20px"
      >
        <Flex
          align="center"
          mb={{ sm: "10px", md: "0px" }}
          direction={{ sm: "column", md: "row" }}
          w={{ sm: "100%" }}
          textAlign={{ sm: "center", md: "start" }}
        >
          <Avatar
            me={{ md: "22px" }}
            src={avatar5}
            w="80px"
            h="80px"
            borderRadius="100px"
          />
          <Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
            <Text
              fontSize={{ sm: "lg", lg: "xl" }}
              color={textColor}
              fontWeight="bold"
              ms={{ sm: "8px", md: "0px" }}
            >
              @{user && user.username}
            </Text>
            <Text
              fontSize={{ sm: "lg", lg: "xl" }}
              color={textColor}
              fontWeight="bold"
              ms={{ sm: "8px", md: "0px" }}
            >
              {user && user.attributes.name}
            </Text>
            <Text
              fontSize={{ sm: "sm", md: "md" }}
              color={emailColor}
              fontWeight="semibold"
            >
              {user && user.attributes.email.toLowerCase()}
            </Text>
          </Flex>
        </Flex>
        <Flex
          direction={{ sm: "column", lg: "column" }}
          w={{ sm: "100%", md: "50%", lg: "auto" }}
        >
          <Button p="0px" bg="transparent" variant="no-effects" my={"1rem"}>
            <Flex
              align="center"
              w={{ sm: "100%", lg: "auto" }}
              bg={colorMode === "dark" ? "navy.900" : "navy.500"}
              borderRadius="8px"
              justifyContent="center"
              py="10px"
              px="1rem"
              boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.06)"
              cursor="pointer"
            >
              <Icon color={"white"} as={FaFileAlt} me="6px" />
              <Text
                fontSize="xs"
                color={"white"}
                fontWeight="bold"
                flexWrap={true}
              >
                TUTORIALS
              </Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>

      <Grid templateColumns={{ sm: "1fr", xl: "repeat(2, 1fr)" }} gap="22px">
        <Card p="16px">
          <CardHeader p="12px 5px" mb="12px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              PLATFORM CONFIGURATION
            </Text>
          </CardHeader>
          <CardBody px="5px">
            <Flex direction="column">
              <Text fontSize="sm" color="gray.400" fontWeight="600" mb="20px">
                SIGNALS & NOTIFICATIONS
              </Text>
              <Flex align="center" mb="20px">
                <Switch colorScheme="navy" me="10px" />
                <Text
                  noOfLines={1}
                  fontSize="md"
                  color="gray.400"
                  fontWeight="400"
                >
                  Push Notifications
                </Text>
              </Flex>
              <Flex align="center" mb="20px">
                <Switch colorScheme="navy" me="10px" />
                <Text
                  noOfLines={1}
                  fontSize="md"
                  color="gray.400"
                  fontWeight="400"
                >
                  Email
                </Text>
              </Flex>
              <Flex align="center" mb="20px">
                <Switch colorScheme="navy" me="10px" />
                <Text
                  noOfLines={1}
                  fontSize="md"
                  color="gray.400"
                  fontWeight="400"
                >
                  SMS
                </Text>
              </Flex>
              {profile &&
                (profile.hasiOSSession || profile.hasAndroidSession ? (
                  <Flex
                    align="center"
                    mb="20px"
                    border={"1px"}
                    borderColor={"#b3b3b3"}
                    borderRadius={15}
                    paddingTop={10}
                    paddingBottom={10}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems={"center"}
                  >
                    <Text fontSize="lg" color={textColor} fontWeight="bold">
                      CURRENT DEVICES
                    </Text>
                    <TablesDevice
                      deviceOSName={profile.deviceOSName}
                      deviceModelName={profile.deviceModelName}
                      deviceName={profile.deviceName}
                      deviceBrand={profile.deviceBrand}
                      activeDate={profile.activeDate}
                      deleteDevice={() => deleteDevice()}
                    />
                  </Flex>
                ) : null)}
            </Flex>
          </CardBody>
        </Card>
        <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
          <CardHeader p="12px 5px" mb="12px">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              PROFILE INFORMATION
            </Text>
          </CardHeader>
          <CardBody px="5px">
            <Flex direction="column">
              <Flex align="center" mb="18px">
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                >
                  Complete Name:{" "}
                </Text>
                <Text fontSize="md" color="gray.400" fontWeight="400">
                  {user && user.attributes.name}
                </Text>
              </Flex>
              <Flex align="center" mb="18px">
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                >
                  Phone Number:{" "}
                </Text>
                <Text fontSize="md" color="gray.400" fontWeight="400">
                  {user && user.attributes.phone_number}
                </Text>
              </Flex>
              <Flex align="center" mb="18px">
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                >
                  Email:{" "}
                </Text>
                <Text fontSize="md" color="gray.400" fontWeight="400">
                  {user && user.attributes.email.toLowerCase()}
                </Text>
              </Flex>

              <Flex align="center" mb="18px">
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                >
                  Subscription Status:{" "}
                </Text>
                <Badge
                  bg={
                    profile && profile.forexSubscription
                      ? "green.400"
                      : "gray.400"
                  }
                  color={"white"}
                  fontSize="16px"
                  p="3px 10px"
                  borderRadius="8px"
                >
                  {profile &&
                    (profile.forexSubscription ? "Active" : "Inactive")}
                </Badge>
              </Flex>
              {profile && profile.forexSubscription && (
                <Flex align="center" mb="18px">
                  <Text
                    fontSize="md"
                    color={textColor}
                    fontWeight="bold"
                    me="10px"
                  >
                    Currently Plan:{" "}
                  </Text>
                  <Badge
                    bg="green.400"
                    color={"white"}
                    fontSize="16px"
                    p="3px 10px"
                    borderRadius="8px"
                  >
                    {profile.currentlyPlan}
                  </Badge>
                </Flex>
              )}

              <Flex align="center" mb="18px">
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                >
                  Certificate Disclaimer:{" "}
                </Text>
                {profile && (
                  <Tooltip
                    title="You have to sign the Certificates to enable all BMaker Features"
                    placement="bottom"
                  >
                    <Badge
                      bg={profile.hasSigned ? "green.400" : "gray.400"}
                      color={"white"}
                      fontSize="16px"
                      p="3px 10px"
                      borderRadius="8px"
                    >
                      {profile.hasSigned ? "Signed" : "Unsigned"}
                    </Badge>
                  </Tooltip>
                )}

                {profile && !profile.isCompletedKYC && (
                  <Tooltip
                    title="You have to complete the KYC process to enable all BMaker Features"
                    placement="bottom"
                  >
                    <BsFillShieldLockFill
                      size={22}
                      color={profile.hasSigned ? "#48bb79" : "#a0aec0"}
                      style={{ marginLeft: 10 }}
                    />
                  </Tooltip>
                )}
              </Flex>

              <Flex align="center" mb="18px">
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  me="10px"
                >
                  KYC Verification:{" "}
                </Text>
                <Tooltip
                  title="You have to complete the KYC process to enable all BMaker Features"
                  placement="bottom"
                >
                  <Badge
                    bg={
                      profile && profile.isCompletedKYC
                        ? "green.400"
                        : "red.400"
                    }
                    color={"white"}
                    fontSize="16px"
                    p="3px 10px"
                    borderRadius="8px"
                  >
                    {profile &&
                      (profile.isCompletedKYC ? "Complete" : "Incomplete")}
                  </Badge>
                </Tooltip>

                {profile && !profile.isCompletedKYC && (
                  <Tooltip
                    title="You have to complete the KYC process to enable all BMaker Features"
                    placement="bottom"
                  >
                    <HiBellAlert
                      size={22}
                      color={"#f56565"}
                      style={{ marginLeft: 10 }}
                    />
                  </Tooltip>
                )}
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Grid>
    </Flex>
  );
}

export default Profile;
