export const schema = {
  models: {
    Noticia: {
      name: "Noticia",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        title: {
          name: "title",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        subtitle: {
          name: "subtitle",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        timestamp: {
          name: "timestamp",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        optionalImage: {
          name: "optionalImage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        source: {
          name: "source",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        url: {
          name: "url",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        author: {
          name: "author",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        publishedDate: {
          name: "publishedDate",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        visibility: {
          name: "visibility",
          isArray: false,
          type: {
            enum: "NotificationVisibility",
          },
          isRequired: true,
          attributes: [],
        },
        coverImage: {
          name: "coverImage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Noticias",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                provider: "identityPool",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Tutoriales: {
      name: "Tutoriales",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        title: {
          name: "title",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        subtitle: {
          name: "subtitle",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        timestamp: {
          name: "timestamp",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        optionalImage: {
          name: "optionalImage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        source: {
          name: "source",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        url: {
          name: "url",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        type: {
          name: "type",
          isArray: false,
          type: {
            enum: "TypeTutorial",
          },
          isRequired: true,
          attributes: [],
        },
        publishedDate: {
          name: "publishedDate",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        coverImage: {
          name: "coverImage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Tutoriales",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                provider: "identityPool",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    AuditedAccounts: {
      name: "AuditedAccounts",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        url: {
          name: "url",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        gains: {
          name: "gains",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "AuditedAccounts",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                provider: "identityPool",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Strategy: {
      name: "Strategy",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        url: {
          name: "url",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Strategies",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                provider: "identityPool",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Envivos: {
      name: "Envivos",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        title: {
          name: "title",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        subtitle: {
          name: "subtitle",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        timestamp: {
          name: "timestamp",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        publishedDate: {
          name: "publishedDate",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        untilDate: {
          name: "untilDate",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        envivoDate: {
          name: "envivoDate",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        envivoTime: {
          name: "envivoTime",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        platform: {
          name: "platform",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        url: {
          name: "url",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        visibility: {
          name: "visibility",
          isArray: false,
          type: {
            enum: "NotificationVisibility",
          },
          isRequired: true,
          attributes: [],
        },
        coverImage: {
          name: "coverImage",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Envivos",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                provider: "identityPool",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Mentalidad: {
      name: "Mentalidad",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        title: {
          name: "title",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        subtitle: {
          name: "subtitle",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        description: {
          name: "description",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        timestamp: {
          name: "timestamp",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        visibility: {
          name: "visibility",
          isArray: false,
          type: {
            enum: "NotificationVisibility",
          },
          isRequired: true,
          attributes: [],
        },
        optionalImage: {
          name: "optionalImage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        source: {
          name: "source",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        url: {
          name: "url",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        coverImage: {
          name: "coverImage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Mentalidads",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                provider: "identityPool",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Signal: {
      name: "Signal",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        mode: {
          name: "mode",
          isArray: false,
          type: {
            enum: "SignalMode",
          },
          isRequired: true,
          attributes: [],
        },
        title: {
          name: "title",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        subtitle: {
          name: "subtitle",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        timestamp: {
          name: "timestamp",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        pair: {
          name: "pair",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        operation: {
          name: "operation",
          isArray: false,
          type: {
            enum: "SignalOperation",
          },
          isRequired: true,
          attributes: [],
        },
        position: {
          name: "position",
          isArray: false,
          type: {
            enum: "SignalPosition",
          },
          isRequired: true,
          attributes: [],
        },
        price: {
          name: "price",
          isArray: false,
          type: "Float",
          isRequired: true,
          attributes: [],
        },
        takeProfit: {
          name: "takeProfit",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        stopLoss: {
          name: "stopLoss",
          isArray: false,
          type: "Float",
          isRequired: false,
          attributes: [],
        },
        visibility: {
          name: "visibility",
          isArray: false,
          type: {
            enum: "NotificationVisibility",
          },
          isRequired: true,
          attributes: [],
        },
        coverImage: {
          name: "coverImage",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        expiredAt: {
          name: "expiredAt",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Signals",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                provider: "identityPool",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Modo: {
      name: "Modo",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        automatic: {
          name: "automatic",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
        lastUpdate: {
          name: "lastUpdate",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Modos",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                provider: "identityPool",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Certificate: {
      name: "Certificate",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        documentUnsigned: {
          name: "documentUnsigned",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        documentSigned: {
          name: "documentSigned",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        isSigned: {
          name: "isSigned",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Certificates",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                provider: "identityPool",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    User: {
      name: "User",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        name: {
          name: "name",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        username: {
          name: "username",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        phone: {
          name: "phone",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        email: {
          name: "email",
          isArray: false,
          type: "AWSEmail",
          isRequired: true,
          attributes: [],
        },
        isCommercial: {
          name: "isCommercial",
          isArray: false,
          type: "Boolean",
          isRequired: true,
          attributes: [],
        },
        kyc: {
          name: "kyc",
          isArray: false,
          type: {
            nonModel: "KYC",
          },
          isRequired: false,
          attributes: [],
        },
        session: {
          name: "session",
          isArray: false,
          type: {
            nonModel: "Session",
          },
          isRequired: false,
          attributes: [],
        },
        plan: {
          name: "plan",
          isArray: false,
          type: {
            nonModel: "SubscriptionPlan",
          },
          isRequired: false,
          attributes: [],
        },
        dateSigned: {
          name: "dateSigned",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        expoToken: {
          name: "expoToken",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Users",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byEmail",
            fields: ["email", "username"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                provider: "identityPool",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
  },
  enums: {
    NotificationVisibility: {
      name: "NotificationVisibility",
      values: ["PUBLIC", "PRIVATE"],
    },
    TypeTutorial: {
      name: "TypeTutorial",
      values: ["VIDEO", "TEXT"],
    },
    SignalOperation: {
      name: "SignalOperation",
      values: ["OPEN", "CLOSE"],
    },
    SignalPosition: {
      name: "SignalPosition",
      values: ["BUY", "SELL"],
    },
    SignalMode: {
      name: "SignalMode",
      values: ["MANUAL", "AUTOMATIC"],
    },
    SubscriptionPlanType: {
      name: "SubscriptionPlanType",
      values: ["PROFESSIONAL", "PLATINUM", "PREMIUM"],
    },
  },
  nonModels: {
    SubscriptionPlan: {
      name: "SubscriptionPlan",
      fields: {
        type: {
          name: "type",
          isArray: false,
          type: {
            enum: "SubscriptionPlanType",
          },
          isRequired: true,
          attributes: [],
        },
        activationDate: {
          name: "activationDate",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
      },
    },
    KYC: {
      name: "KYC",
      fields: {
        date: {
          name: "date",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
      },
    },
    Session: {
      name: "Session",
      fields: {
        osName: {
          name: "osName",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        deviceName: {
          name: "deviceName",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        deviceModel: {
          name: "deviceModel",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        deviceBrand: {
          name: "deviceBrand",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        activeDate: {
          name: "activeDate",
          isArray: false,
          type: "AWSDateTime",
          isRequired: true,
          attributes: [],
        },
      },
    },
  },
  codegenVersion: "3.4.4",
  version: "c38d32275ba25886b7fe061c3c255361",
};
